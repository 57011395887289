/* eslint-disable camelcase */
import { useState, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { BackendService, Authenticator } from '@mdc/services';
import { ConfirmModal, InternalLink, NotificationContext, account } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import { Row, Col, Button, Form } from 'react-bootstrap';

import './RsaKeys.scss';
const { AccountBanner } = account;

const PUBLIC_KEY_FORMATS = {
    'pkcs1': 'BEGIN RSA PUBLIC KEY',
    'pkcs8': 'BEGIN PUBLIC KEY',
    'openssh': 'ssh-rsa'
};

const RsaKeys = ({ user }) => {
    const { notify } = useContext(NotificationContext);
    const { t, ready } = useTranslation();
    const [userPublicKey, setUserPublicKey] = useState(user?.data?.public_key);
    const [isUpdatingRsaKey, setIsUpdatingRsaKey] = useState(false);
    const refTextarea = useRef(null);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const UpdateRsaKey = async (publicKey) => {
        if (user?.isPaidUser) {
            try {
                const [updateApiKey] = await BackendService.updateApiKey({ public_key: publicKey });
                let response = await updateApiKey;
                if (response && response.data) {
                    return true;
                }
                notify({
                    message: t('Request failed. Please try again!'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });
                return false;
            } catch (error) {
                notify({
                    message: t('Request failed. Please try again!'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });
                throw new Error(error);
            }
        }
    };

    const encryptedValue = useMemo(() => {
        let encryptedKey;
        if (!userPublicKey || userPublicKey.includes('**********')) {
            encryptedKey = userPublicKey;
        } else {
            encryptedKey = userPublicKey.slice(0, 15) + '*'.repeat(10) + userPublicKey.slice(userPublicKey.length - 16, userPublicKey.length - 1);
        }

        Authenticator.onStateChange({ ...user.data, public_key: encryptedKey });
        return encryptedKey;
    }, [userPublicKey]);

    const handleUpdate = () => {
        const { current } = refTextarea;
        let shouldUpdate = false;
        let currentFormat = '';
        if (current.value) {
            Object.keys(PUBLIC_KEY_FORMATS).forEach((format) => {
                if (current.value.includes(PUBLIC_KEY_FORMATS[format])) {
                    shouldUpdate = true;
                    currentFormat = format;
                }
            });
        }

        if (shouldUpdate) {
            (async () => {
                const addRsaKeyPromise = await UpdateRsaKey(current.value);

                if (addRsaKeyPromise === true) {
                    setIsUpdatingRsaKey(false);

                    // update displayed key
                    switch (currentFormat) {
                        case 'pkcs1':
                        case 'pkcs8': {
                            const publicKey = current.value.split('-').filter((element) => { return element !== ''; })[1];
                            setUserPublicKey(publicKey);
                            break;
                        }

                        case 'openssh': {
                            const publicKey = current.value.split(' ')[1];
                            setUserPublicKey(publicKey);
                            break;
                        }

                        default:
                            setUserPublicKey(null);
                            break;
                    }

                    notify({
                        message: t('Your RSA Key was set'),
                        type: NOTIFICATION_TYPES.SUCCESS
                    });
                }
            })();
        } else {
            notify({
                message: t('Incorrect RSA Key format. Allowed types are pkcs1, pkcs8, openssh. Please try again.'),
                type: NOTIFICATION_TYPES.CRITICAL
            });
        }
    };

    const handleRemove = () => {
        (async () => {
            setShowDeleteModal(false);
            const removeRsaKeyPromise = await UpdateRsaKey('');
            if (removeRsaKeyPromise) {
                setIsUpdatingRsaKey(false);
                setUserPublicKey(undefined);

                notify({
                    message: t('Your RSA Key was removed'),
                    type: NOTIFICATION_TYPES.SUCCESS
                });
            }
        })();
    };

    const handleTextareaToggle = () => {
        setIsUpdatingRsaKey(true);
    };

    const handleCancel = (evt) => {
        evt.preventDefault();
        setIsUpdatingRsaKey(false);
    };

    const userPublicKeyDom = useMemo(() => {
        if (isUpdatingRsaKey) {
            return <>
                <Form.Group controlId="rsaKeyValue">
                    <Form.Label>{t('Set RSA Key')}</Form.Label>
                    <Form.Control as="textarea" rows={3} ref={refTextarea} />
                </Form.Group>
                <div className='d-flex flex-row align-items-center'>
                    <Button variant="primary" type="submit" className="updateRsaKeyButton" onClick={handleUpdate}>
                        {t('Add RSA Key')}
                    </Button>
                    <InternalLink onClick={handleCancel} to='/' className='ml-3'>{t('Cancel')}</InternalLink>
                </div>
            </>;
        }

        if (encryptedValue) {
            return <>
                {encryptedValue}
                <div className="rsaKeyButton mt-2">
                    <Button variant="primary" className="mr-2" onClick={handleTextareaToggle}>
                        {t('Update RSA Key')}
                    </Button>
                    <Button variant="outline-danger" onClick={() => setShowDeleteModal(true)}>
                        {t('Remove RSA Key')}
                    </Button>
                </div>
            </>;
        }

        return <>
            {t('No RSA Key set yet.')}
            <div className="rsaKeyButton mt-2">
                <Button variant="primary" onClick={handleTextareaToggle}>
                    {t('Set RSA Key')}
                </Button>
            </div>
        </>;
    }, [encryptedValue, isUpdatingRsaKey]);

    const modalDeleteRsa = useMemo(() => {
        return <ConfirmModal
            className="advancedOptionsModal"
            show={showDeleteModal}
            onAccept={handleRemove}
            onCancel={() => setShowDeleteModal(false)}
            title={t('Remove RSA Key')}
            body={<p>{t('Are you sure to remove your RSA key?')}</p>}
            acceptText={t('Yes')}
            cancelText={t('No')}
        />;
    }, [showDeleteModal]);

    const rsaBanner = useMemo(() => {
        const bannerDom = (
            <Row className='banner-text'>
                <Col className='first-sentence'>{t('Upload a Public RSA Key to enhance your data privacy and security.')}</Col>
                <Col className='second-sentence'>
                    {t('Supported key formats: PEM(PUBLIC KEY, RSA PUBLIC KEY), OpenSSH (ssh-rsa).')}
                    {' '}
                    <a href="https://docs.opswat.com/mdcloud/account-management/rsa-key-encryption#why-use-an-rsa-key-for-metadefender-cloud-" rel="noopener noreferrer" target="_blank">{t('Read more')}</a>
                </Col>
            </Row>
        );

        return <AccountBanner contentDom={bannerDom} />;
    }, [t]);

    const dom = useMemo(() => {
        if (!user?.isPaidUser) {
            return <div className='onlyEnterpriseText'>
                {t('This feature is available only on the enterprise licensing')}
            </div>;
        }

        return <>
            {rsaBanner}
            <Row className="rsaSection">
                <Col lg={2} md={3} sm={4}>
                    {t('Public RSA Key:')}
                </Col>
                <Col lg={10} md={9} sm={8} xs={12} className="valueCol">
                    <span className='valuesText w-100'>
                        {userPublicKeyDom}
                    </span>
                </Col>
            </Row>
            {modalDeleteRsa}
        </>;
    }, [user, rsaBanner, t, userPublicKeyDom, modalDeleteRsa, isUpdatingRsaKey, encryptedValue, showDeleteModal]);

    if (!ready) {
        return null;
    }

    return (
        <>
            <h1>{t('Privacy')}</h1>
            <div className='rsaKey'>
                <h2>{t('RSA Key')}</h2>
                {dom}
            </div>
        </>
    );
};

RsaKeys.propTypes = {
    user: PropTypes.object.isRequired,
};

export default RsaKeys;
